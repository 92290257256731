import { PropsWithChildren } from 'react'
import { Box, EmptyState, EmptyStateActions, EmptyStateDescription, EmptyStateIllustration, EmptyStateTitle } from '@vp/swan'

interface Props {
  heading: string;
  information: string;
};

export const EmptyStateCard = ({ heading, information, children }: PropsWithChildren<Props>) => (
  <Box my='between-subsections'>
    <EmptyState>
      <EmptyStateIllustration illustrationType='generic' />
      <EmptyStateTitle>{heading}</EmptyStateTitle>
      <EmptyStateDescription>{information}</EmptyStateDescription>
      <EmptyStateActions>{children}</EmptyStateActions>
    </EmptyState>
  </Box>
)

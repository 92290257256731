import { useLocalization } from '../../hooks/useLocalizations'
import { TrackedLink } from '../TrackedLink'
import { EmptyStateCard } from '../EmptyStateCard'

interface Props {
  onRetry: () => void;
}

export const SomethingWentWrongCard = ({ onRetry }: Props) => {
  const { t } = useLocalization()
  return (
    <EmptyStateCard heading={t('somethingWentWrong')} information={t('contentFailedToLoad')}>
      <TrackedLink
        onClick={onRetry}
        trackingConfig={{ action: 'something_went_wrong_try_again_clicked' }}
      >
        {t('tryAgain')}
      </TrackedLink>
    </EmptyStateCard>
  )
}

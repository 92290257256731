import { useEffect } from 'react'
import { useFetchUserOrders } from '../../hooks/useFetchUserOrders'
import { Order } from './Order'
import { Order as DaliOrderOrder } from '../../client/DaliOrder/Order'
import { OrderUpdateSection } from './OrderUpdateSection/OrderUpdateSection'
import { isLocal } from '../../common/authConfig'
import { useLogger } from '@vp/ubik-context'
import { DEFAULT_LOCALE } from '../../utils/constants'

export function OrderUpdates () {
  const logger = useLogger()
  const isProduction = !isLocal
  const { isLoading, error, refetch, data } = useFetchUserOrders(isProduction)

  useEffect(() => {
    if (error) {
      logger.error(error as Error)
    }
  }, [error])

  if (isLoading) {
    return <></>
  }

  const firstTwoOrders = (data || []).slice(0, 2)
  const orders: Order[] = firstTwoOrders.map((order: DaliOrderOrder) => ({
    number: order.number,
    productName: order.representativeLineItem.productName,
    productId: order.representativeLineItem.productId,
    productVersion: order.representativeLineItem.productVersion,
    previewUrl: order.representativeLineItem.livePreviewUrl,
    locale: order.locale || DEFAULT_LOCALE,
    soonestArrivalDate: order.soonestArrivalDate,
    documentUrl: order.representativeLineItem.documentUrl,
    fulfillmentOptions: order.representativeLineItem.fulfillmentOptions,
  }))

  return <OrderUpdateSection orders={orders} hasError={!!error} refetch={refetch} />
}

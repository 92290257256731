import { useSwanStyleKeys } from '@vp/ubik-context'
import { FlexBox, Hidden, Span, Typography } from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'
import { accountSettingsNavigationClickedData } from '../../common/tracking/trackingData'
import { useUrls } from '../../common/urls/provider'
import { useFetchUserProfile } from '../../hooks/useFetchUserProfile'
import { TrackedLink } from '../TrackedLink'

export const WelcomeHeader = () => {
  useSwanStyleKeys(['hidden', 'button'])

  const { t } = useLocalization()
  const { data: userProfile } = useFetchUserProfile()
  const { linkTo } = useUrls()

  const helloText = t('welcomeHeading.helloText')
  const firstName = userProfile?.firstName

  return (
    <FlexBox justifyContent='space-between' alignItems='center'>
      <FlexBox flexDirection='column'>
        <Typography as='h1' fontSkin='title-headline'>{!firstName ? `${helloText}.` : `${helloText}, ${firstName}.`}</Typography>
        <Span fontSkin='body-standard'>{t('welcomeHeading.secondaryText')}</Span>
      </FlexBox>
      <Hidden xs>
        <TrackedLink
          asButton
          href={linkTo.accountSettings()}
          trackingConfig={accountSettingsNavigationClickedData}
        >
          {t('welcomeHeading.accountSettingsText')}
        </TrackedLink>
      </Hidden>
    </FlexBox>
  )
}

import { PropsWithChildren } from 'react'
import { ErrorBoundaryProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useLogger } from '@vp/ubik-context'

export const ErrorBoundary = ({
  children,
  onError,
  ...props
}: PropsWithChildren<ErrorBoundaryProps>) => {
  const logger = useLogger()
  return (
    <ReactErrorBoundary
      {...props}
      onError={(err, errInfo) => {
        logger.error(new Error(err.message), errInfo)
        onError?.(err, errInfo)
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export type ProjectConstructorParams = {
  locale: string;
  id: string;
  name: string;
  lastEdited: Date;
  editUrl: string | null;
  displayUrl: string | null;
  productKey: string;
  productVersion: string;
}

export abstract class Project {
  readonly locale: string
  readonly id: string
  readonly name: string
  readonly lastEdited: Date
  readonly editUrl: string | null
  readonly displayUrl: string | null
  protected productKey: string
  protected productVersion: string

  constructor ({
    locale,
    id,
    name,
    editUrl,
    displayUrl,
    lastEdited,
    productKey,
    productVersion,
  }: ProjectConstructorParams) {
    this.locale = locale
    this.id = id
    this.name = name
    this.displayUrl = displayUrl
    this.productKey = productKey
    this.productVersion = productVersion
    this.lastEdited = lastEdited
    this.editUrl = editUrl
  }

  get previewImageUrl (): string | null | undefined {
    return this.buildPreviewUrl()
  }

  protected abstract buildPreviewUrl (): string | null | undefined
}

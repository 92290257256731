import { createContext, PropsWithChildren, useContext } from 'react'
import { filterExcludedLocales, isAvailableInLocale } from '../locale/helper'

export const urlIdMap = {
  accountSettings: {
    id: 'ma:loginAndSecurity',
  },
  brandKit: {
    id: 'other:brandkit',
  },
  brandShop: {
    id: 'other:brandshop',
  },
  businessCards: {
    id: 'businessCards',
  },
  designServices: {
    id: 'designServices',
    availableIn: filterExcludedLocales(['en-IN']),
    overrides: {
      'de-AT': '/designservice',
      'de-CH': '/designservice',
      'fi-FI': '/suunnittelu',
      'fr-CH': '/fr/services-graphiques',
      'it-CH': '/it/servizi-grafici',
    },
  },
  invitationsAndStationery: {
    id: 'invitationsAndStationery',
  },
  marketingMaterials: {
    id: 'marketingMaterials',
  },
  myDesignServices: {
    id: 'ma:myDesignServices',
    availableIn: filterExcludedLocales(['en-IN']),
  },
  myOrderHistory: {
    id: 'ma:orderHistory',
  },
  myProjects: {
    id: 'ma:myProjects',
  },
  websites: {
    id: 'websitesTbyb',
    availableIn: filterExcludedLocales(['en-IN']),
  },
  workspace: {
    id: 'workspace:home',
  },
} as const

type UrlKey = keyof typeof urlIdMap
export type UrlId = (typeof urlIdMap)[UrlKey]['id']
export type UrlIdAndMetadata = {
  id: UrlId;
  availableIn?: readonly string[];
  overrides?: Record<string, string>;
}

export type Urls = Record<UrlId, string>

type UrlsContextType = {
  linkTo: {
    accountSettings: () => string;
    brandKit: (id?: string) => string;
    brandShop: (id?: string) => string;
    businessCard: () => string;
    designServices: () => string;
    home: () => string;
    invitationsAndStationery: () => string;
    marketingMaterials: () => string;
    myDesignServices: () => string;
    myOrderHistory: () => string;
    myProjects: () => string;
    orderDetails: (orderNumber: string) => string;
    websites: () => string;
    workspace: () => string;
  };
}

const UrlsContext = createContext<UrlsContextType>({
  linkTo: {} as UrlsContextType['linkTo'],
})

export type UrlsContextProviderProps = PropsWithChildren<{
  urls: Urls;
  locale: string;
}>

export const UrlProvider = ({ children, urls, locale }: UrlsContextProviderProps) => {
  const buildUrl = (urlId: UrlIdAndMetadata): string => {
    const id = urlId.id
    const url = urls[id]
    if (url === undefined && isAvailableInLocale(urlId, locale)) {
      throw Error(`InternalError: unable to retrieve url for id ${id}`)
    }
    return url
  }

  const builtUrls: Urls = {} as Urls
  Object.keys(urlIdMap).forEach((key) => {
    const urlKey = key as UrlKey
    const urlId = urlIdMap[urlKey]
    builtUrls[urlId.id] = buildUrl(urlId)
  })

  const linkTo = {
    accountSettings: () => builtUrls[urlIdMap.accountSettings.id],
    brandKit: (id?: string) => `${builtUrls[urlIdMap.brandKit.id]}${id ? `?brandId=${id}` : ''}`,
    brandShop: (id?: string) => `${builtUrls[urlIdMap.brandShop.id]}${id ? `?brandId=${id}` : ''}`,
    businessCard: () => builtUrls[urlIdMap.businessCards.id],
    designServices: () => builtUrls[urlIdMap.designServices.id],
    home: () => '/',
    invitationsAndStationery: () => builtUrls[urlIdMap.invitationsAndStationery.id],
    marketingMaterials: () => builtUrls[urlIdMap.marketingMaterials.id],
    myDesignServices: () => builtUrls[urlIdMap.myDesignServices.id],
    myOrderHistory: () => builtUrls[urlIdMap.myOrderHistory.id],
    myProjects: () => builtUrls[urlIdMap.myProjects.id],
    orderDetails: (orderNumber: string) => `/od?orderId=${orderNumber}`,
    websites: () => builtUrls[urlIdMap.websites.id],
    workspace: () => builtUrls[urlIdMap.workspace.id],
  }
  return <UrlsContext.Provider value={{ linkTo }}>{children}</UrlsContext.Provider>
}

export const useUrls = () => {
  const urlContext = useContext(UrlsContext)
  return { linkTo: urlContext.linkTo }
}

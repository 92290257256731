import { Box, FlexBox, H2, Spinner } from '@vp/swan'
import { useUrls } from '../../common/urls/provider'
import { useLocalization } from '../../hooks/useLocalizations'
import { TrackedLink } from '../TrackedLink'
import { latestProjectsViewAllNavigationClickedData } from '../../common/tracking/trackingData'
import { SomethingWentWrongCard } from './SomethingWentWrongCard'
import { NoPreviousProjectsCard } from './NoPreviousProjectsCard'
import { useFetchUserProjects } from '../../hooks/useFetchUserProjects'
import { ProjectTileList } from './ProjectTileList'

export const LatestProjects = () => {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  const { isLoading, data: projects, error, refetch } = useFetchUserProjects(0, 4)

  return (
    <FlexBox as='section' flexDirection='column' justifyContent='space-between'>
      <FlexBox justifyContent='space-between' alignItems='center'>
        <H2 fontSkin='title-section'>{t('latestProjects.heading')}</H2>
        <TrackedLink
          href={linkTo.myProjects()}
          trackingConfig={latestProjectsViewAllNavigationClickedData}
        >
          {t('viewAll')}
        </TrackedLink>
      </FlexBox>
      <Box style={{ flexGrow: 2 }}>
        {isLoading && <Spinner accessibleText={t('loadingLabel')} />}
        {!isLoading && error && <SomethingWentWrongCard onRetry={refetch} />}
        {!isLoading && !error && (
          !projects || projects?.length === 0 ? (<NoPreviousProjectsCard />) : <ProjectTileList projects={projects} />
        )}
      </Box>
    </FlexBox>
  )
}

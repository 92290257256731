import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useIdentity, useLogger } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useUrls } from '../../common/urls/provider'
import {
  accountSnapshotWebsitesEmptyTileNavigationClickedData,
  accountSnapshotWebsitesTileNavigationClickedData,
} from '../../common/tracking/trackingData'
import { FetchError, FetchResponse, fetchWithAuthorizationHeader } from '../../client/fetchClient'
import { isLocal } from '../../common/authConfig'
import { Tile } from './Tile'

export const WebsitesTile = () => {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  const logger = useLogger()

  const { error, isLoading, data: count } = useFetchWebsiteCount()

  useEffect(() => {
    if (error) {
      logger.error(new Error('Account snapshot: failed to fetch website count'), error as Error)
    }
  }, [error])

  return (
    <Tile
      title={t('accountSnapshot.websitesHeading')}
      count={error ? 0 : count ?? 0}
      ctaText={t('accountSnapshot.createWebsiteCta')}
      emptyLink={linkTo.websites()}
      emptyStateClickEvent={accountSnapshotWebsitesEmptyTileNavigationClickedData}
      withCountLink={linkTo.workspace()}
      withCountClickEvent={accountSnapshotWebsitesTileNavigationClickedData}
      loading={isLoading}
    />
  )
}

const useFetchWebsiteCount = (): FetchResponse<number> => {
  const { auth } = useIdentity()
  return useQuery({
    queryKey: ['websiteCount'],
    queryFn: () => fetchWebsiteCount(auth?.getAuthorizationHeader() || '', auth?.getCanonicalId() || ''),
    enabled: !!auth?.getCanonicalId() && !!auth?.getAuthorizationHeader(),
  })
}

type WebsitesResponse = {
  wixSites: Website[];
}

type Website = {
  id: string;
  htmlAppId: string;
  name: string;
  displayName: string;
  createdDate: string;
  published: boolean;
  premium: boolean;
  viewUrl: string;
  editUrl: string;
  thumbnail: string;
  ownerAccountId: string;
  contributorAccountIds: string[];
  editorType: string;
  blocked: boolean;
  namespace: string;
  domainConnected: boolean;
}

const fetchWebsiteCount = async (authorizationHeader: string, canonicalId: string) => {
  const isProduction = !isLocal
  const baseUrl = `https://${isProduction ? 'prod' : 'qa'}.partner-integration.digital.vpsvc.com/api/v1`
  const url = new URL(`${baseUrl}/products/${canonicalId}`)

  try {
    const data: WebsitesResponse = await fetchWithAuthorizationHeader(String(url), authorizationHeader)
    return data.wixSites.length
  } catch (e) {
    if (e instanceof FetchError && e.status === 404) {
      return 0
    }
    throw e
  }
}

import { getTracking as VPTracking } from '@vp/tracking'
import { NavigationClickedEventData } from './trackingData'

const NAVIGATION_CLICKED_EVENT_NAME = 'Navigation Clicked'

export const getTracking = () => {
  const tracking = VPTracking()
  const sendNavigationClickedEvent = (navigationClickedEventData: NavigationClickedEventData) => {
    if (!tracking) {
      console.info('Tracking is not available')
      return
    }
    tracking.track(NAVIGATION_CLICKED_EVENT_NAME, navigationClickedEventData, {})
  }
  return {
    sendNavigationClickedEvent,
  }
}

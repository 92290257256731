import { fetchWithAuthorizationHeader } from '../../../client/fetchClient'

type fetchMSXProductNamesParams = {
  locale: string;
  productKeys: string[];
  authorizationHeader: string;
}

type MSXProductNamesResponse = Record<string, string[]>

const MSX_BASE_URL = 'https://merchandising-site-experience.prod.merch.vpsvc.com/api/v1'
const REQUESTOR = 'my-account-homepage'
const TENANT = 'vistaprint'

export const fetchMSXProductNames = async ({
  locale,
  productKeys,
  authorizationHeader,
}: fetchMSXProductNamesParams): Promise<MSXProductNamesResponse> => {
  const url = new URL(`${MSX_BASE_URL}/tenant/${TENANT}/culture/${locale}/productName`)
  url.searchParams.append('requestor', REQUESTOR)

  const body = JSON.stringify({
    productKeys,
  })

  const request = new Request(url.toString(), {
    method: 'POST',
    body,
  })

  return await fetchWithAuthorizationHeader(request, authorizationHeader)
}

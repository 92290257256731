import { LineItem } from './LineItem'

export type OrderConstructorParams = {
  number: string;
  locale?: string;
  status: string;
  lineItems: LineItem[];
}

export class Order {
  readonly number: string
  readonly locale: string | undefined
  readonly status: string
  readonly lineItems: LineItem[]
  readonly itemWithClosestArrivalDate: LineItem | null

  constructor ({ number, locale, status, lineItems }: OrderConstructorParams) {
    this.number = number
    this.locale = locale
    this.status = status
    this.lineItems = lineItems
    this.itemWithClosestArrivalDate = this.getItemWithClosestArrivalDate()
  }

  get representativeLineItem (): LineItem {
    return this.itemWithClosestArrivalDate || this.lineItems[0]
  }

  get soonestArrivalDate (): Date | undefined {
    return this.itemWithClosestArrivalDate?.estimatedArrivalDate
  }

  private getItemWithClosestArrivalDate (): LineItem | null {
    const deliverableItems = this.getLineItemsDeliverable()
    if (deliverableItems.length === 0) {
      return null
    }

    const deliverableItemsSortedByArrivalDate = LineItem.sortItemsByDatesAsc(deliverableItems)
    return deliverableItemsSortedByArrivalDate[0]
  }

  private getLineItemsDeliverable (): LineItem[] {
    return this.lineItems.filter((lineItem) => lineItem.isDeliverable)
  }
}

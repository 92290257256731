import { fetchWithAuthorizationHeader } from '../../../client/fetchClient'
import { AlbelliResponse } from '../types/AlbelliResponse'

type fetchAlbelliProjectParams = {
  docId: string;
  canonicalId: string;
  authorizationHeader: string;
}

export const fetchAlbelliProject = async ({
  docId,
  canonicalId,
  authorizationHeader,
}: fetchAlbelliProjectParams): Promise<AlbelliResponse> => {
  const baseUrl = 'https://albelli.design.vpsvc.com'
  const url = new URL(`${baseUrl}/api/v1/documents/${docId}`)
  url.searchParams.append('ownerId', canonicalId)
  url.searchParams.append('includePreviewImageUrl', 'true')

  return await fetchWithAuthorizationHeader(String(url), authorizationHeader)
}

import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useIdentity, useLogger } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useUrls } from '../../common/urls/provider'
import {
  accountSnapshotProjectsEmptyTileNavigationClickedData,
  accountSnapshotProjectsTileNavigationClickedData
} from '../../common/tracking/trackingData'
import { FetchResponse, fetchWithAuthorizationHeader } from '../../client/fetchClient'
import { Tenants, WesCountResponse } from '../../common/projects/types/WesResponse'
import { Tile } from './Tile'

export const ProjectsTile = () => {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  const logger = useLogger()

  const { error, isLoading, data: count } = useFetchUserProjectsCount()

  useEffect(() => {
    if (error) {
      logger.error(new Error('Account snapshot: failed to fetch projects count'), error as Error)
    }
  }, [error])

  return (
    <Tile
      title={t('accountSnapshot.projectsHeading')}
      count={error ? 0 : count ?? 0}
      ctaText={t('accountSnapshot.startProjectCta')}
      emptyLink={linkTo.home()}
      emptyStateClickEvent={accountSnapshotProjectsEmptyTileNavigationClickedData}
      withCountLink={linkTo.myProjects()}
      withCountClickEvent={accountSnapshotProjectsTileNavigationClickedData}
      loading={isLoading}
    />
  )
}

const useFetchUserProjectsCount = (): FetchResponse<number> => {
  const { auth } = useIdentity()
  return useQuery({
    queryKey: ['myProjectCount'],
    queryFn: () => fetchUserProjectCount(auth?.getAuthorizationHeader() ?? ''),
    enabled: !!auth?.getAuthorizationHeader(),
  })
}

const fetchUserProjectCount = async (authorizationHeader: string) => {
  const url = new URL('https://workentityservice.docext.cimpress.io/api/v2/works/count')
  url.searchParams.append('tenants', `${Tenants.vistaprint},${Tenants.albelli}`)
  url.searchParams.append('from', 'my-account-homepage')
  const response: WesCountResponse = await fetchWithAuthorizationHeader(
    String(url),
    authorizationHeader
  )
  return response.ownedWorks
}

import { UrlIdAndMetadata } from '../urls/provider'
import { locales } from './locales'

export const isAvailableInLocale = (urlId: UrlIdAndMetadata, locale: string) =>
  !urlId.availableIn ||
  urlId.availableIn.map((l) => l.toLowerCase()).includes(locale.toLowerCase())

export const filterExcludedLocales = (excludedLocales: string[]) => {
  return locales.filter((locale) => !excludedLocales.includes(locale))
}

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Box, Column, GridContainer, Row } from '@vp/swan'
import { useIdentity, useStyles, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { UrlProvider, Urls } from '../common/urls/provider'
import { queryRetry } from '../client/fetchClient'

import { stylesheet } from './dashboard.css'

import { WelcomeHeader } from './WelcomeHeader'
import { AccountSnapshot } from './AccountSnapshot'
import { OrderUpdates } from './OrderUpdates'
import { LatestProjects } from './LatestProjects'

export interface Props {
  locale: string;
  localizations: { [key: string]: any }
  urls: Urls;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: queryRetry,
    },
  },
})

export const Fragment = ({ urls }: Props) => {
  useSwanStyleKeys(['core', 'grid', 'spinner', 'standardTile', 'emptyState'])
  useStyles(stylesheet)

  const { locale } = useUserContext()
  const { identity } = useIdentity()

  if (!identity?.authorizationHeader) {
    return <></>
  }

  return (
    <UrlProvider urls={urls} locale={locale}>
      <QueryClientProvider client={queryClient}>
        <GridContainer className='dashboard-page-content'>
          <Row marginBottom='between-sections'>
            <Column span={12}>
              <WelcomeHeader />
            </Column>
          </Row>
          <Row marginBottom='between-sections'>
            <Column span={6} spanXs={12}>
              <Box marginBottom={{ xs: 'between-sections', md: '0' }}>
                <AccountSnapshot />
              </Box>
            </Column>
            <Column span={6} spanXs={12}>
              <OrderUpdates />
            </Column>
          </Row>
          <Row marginBottom='between-sections'>
            <Column span={12}>
              <LatestProjects />
            </Column>
          </Row>
        </GridContainer>
      </QueryClientProvider>
    </UrlProvider>
  )
}

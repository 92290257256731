export const enum Tenants {
  albelli = 'VISTAPRINT-ALBELLI-PROD',
  vistaprint = 'VISTAPRINT-PROD',
  logomaker = 'LOGO-UNPURCHASED-PROD',
}

export type Tenant = Tenants.albelli | Tenants.vistaprint | Tenants.logomaker

export interface WesCountResponse {
  ownerId: string;
  ownedWorks: number;
}

export type WesResponse = {
  tenant: Tenant;
  workId: string;
  workRevisionId: string;
  workName: string;
  ownerId: number;
  created: string;
  modified: string;
  merchandising: WesMerchandising;
  design: WesDesign;
  product: WesProduct;
}

type WesProduct = {
  key: string;
  version: string;
}

type WesDesign = {
  editUrl: string | null;
  designUrl: string | null;
  displayUrl: string | null;
  manufactureUrl: string | null;
  docRefUrl: null;
  metadata: {
    previewUrl: string;
  } | null;
}

type WesMerchandising = {
  quantity: number;
  mpvUrl: string;
  merchandisingSelections: {
    Shape: string;
    Finish: string;
    Corners: string;
    Backsides: string;
    productOrientation: string;
  };
  metadata?: {
    productImageUrl?: string;
    productName?: string;
  };
}

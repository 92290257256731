import { Project, ProjectConstructorParams } from '../Project'

export type WesProjectConstructorParams = ProjectConstructorParams & {
  merchandisingSelections?: Record<string, string>;
  designPreviewUrl?: string;
}
export class WesProject extends Project {
  protected merchandisingSelections?: Record<string, string>
  protected designPreviewUrl?: string

  constructor (params: WesProjectConstructorParams) {
    super(params)
    this.merchandisingSelections = params.merchandisingSelections
    this.designPreviewUrl = params.designPreviewUrl
  }

  protected buildPreviewUrl (): string | undefined {
    const purcsTenant = 'vistaprint-prod'
    const REQUESTOR = 'my-account-homepage'
    const width = 200

    if (this.designPreviewUrl) {
      return this.designPreviewUrl
    }

    if (!this.productKey || !this.productVersion) {
      return
    }

    if (!this.displayUrl) {
      return
    }

    const url = new URL(
      `https://purcs.design.vpsvc.com/api/v1/${purcsTenant}/${this.locale}/product/${this.productKey}/defaultViewImage`
    )

    url.searchParams.append('productVersion', this.productVersion)
    url.searchParams.append('purpose', 'design.studio.review')
    url.searchParams.append('previewInstructionsUri', this.displayUrl)
    url.searchParams.append('width', String(width))
    url.searchParams.append('requestor', REQUESTOR)

    if (this.merchandisingSelections) {
      url.searchParams.append('optionSelections', JSON.stringify(this.merchandisingSelections))
    }

    return String(url)
  }
}

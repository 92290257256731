import { ComponentProps, MouseEvent, PropsWithChildren } from 'react'
import { Link, Button } from '@vp/swan'
import { getTracking } from '../../common/tracking/trackingUtils'
import { type NavigationClickedEventData } from '../../common/tracking/trackingData'

type TrackedLinkProps = {
  trackingConfig: NavigationClickedEventData;
  asButton?: boolean;
  fullWidth?: boolean;
  skin?: 'primary' | 'secondary';
} & ComponentProps<typeof Link>

export const TrackedLink = ({
  trackingConfig,
  onClick,
  asButton = false,
  fullWidth = false,
  skin = 'secondary',
  ...linkProps
}: TrackedLinkProps) => {
  const { sendNavigationClickedEvent } = getTracking()

  const trackedOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    sendNavigationClickedEvent(trackingConfig)
    return onClick?.(e)
  }

  if (asButton) {
    return (
      <Button
        skin={skin}
        width={fullWidth ? 'full-width' : undefined}
        render={(buttonProps: PropsWithChildren<{ className: string }>) => (
          <Link to={linkProps.href} className={buttonProps.className}>
            {buttonProps.children}
          </Link>
        )}
      >
        {linkProps.children}
      </Button>
    )
  }

  return <Link {...linkProps} onClick={trackedOnClick} />
}

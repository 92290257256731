import { useMemo } from 'react'
import { Brand, useBrandContext } from '@vp/react-brand'
import { useLocalization } from '../../hooks/useLocalizations'
import { useUrls } from '../../common/urls/provider'
import { accountSnapshotBrandKitsTileNavigationClickedData } from '../../common/tracking/trackingData'
import { Tile } from './Tile'

const getNumberOfBrands = (brands: Brand[]) => {
  const groupedBrands = brands.reduce((acc, brand) => {
    if (brand.isFactual) {
      return { ...acc, factual: acc.factual + 1 }
    } else {
      return { ...acc, speculative: acc.speculative + 1 }
    }
  }, { factual: 0, speculative: 0 })

  if (groupedBrands.factual > 0) {
    return groupedBrands.factual
  }
  if (groupedBrands.speculative === 1) {
    return groupedBrands.speculative
  }
  return 0
}

export const BrandKitTile = () => {
  const { brands, isInitialized, hasInitializationFailed: error } = useBrandContext()
  const numBrands = useMemo(() => getNumberOfBrands(brands), [brands])
  const { t } = useLocalization()
  const { linkTo } = useUrls()

  return (
    <Tile
      title={t('accountSnapshot.brandKitsHeading')}
      count={error ? 0 : numBrands ?? 0}
      ctaText={t('accountSnapshot.createBrandKitCta')}
      emptyLink={linkTo.brandKit()}
      emptyStateClickEvent={accountSnapshotBrandKitsTileNavigationClickedData}
      withCountLink={linkTo.brandKit()}
      withCountClickEvent={accountSnapshotBrandKitsTileNavigationClickedData}
      loading={!isInitialized}
    />
  )
}

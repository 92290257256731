export const getTenantAndProductionOverrides = (): {
  tenantOverride: string | undefined;
  productionOverride: boolean | undefined;
} => {
  const url = window.location.href

  const urlObj = parseUrl(url) || parseUrl(url, 'http://example.com')
  if (!urlObj) {
    return { tenantOverride: undefined, productionOverride: undefined }
  }

  const tenantOverride = urlObj.searchParams.get('orderManagementTenant') ?? undefined
  if (!tenantOverride) {
    return { tenantOverride: undefined, productionOverride: undefined }
  }

  const productionOverride = !tenantOverride.includes('DEV')
  return { tenantOverride, productionOverride }
}

const parseUrl = (url: string, baseUrl?: string) => {
  try {
    return new URL(url, baseUrl)
  } catch {
    return null
  }
}

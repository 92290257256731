import { useUrls } from '../../common/urls/provider'
import { useLocalization } from '../../hooks/useLocalizations'
import { latestProjectsCategoryPageNavigationClickedData } from '../../common/tracking/trackingData'
import { TrackedLink } from '../TrackedLink'
import { EmptyStateCard } from '../EmptyStateCard'

export const NoPreviousProjectsCard = () => {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  return (
    <EmptyStateCard heading={t('latestProjects.noProjectsMessage')} information={t('latestProjects.readyToCreateIntro')}>
      <TrackedLink
        asButton
        href={linkTo.businessCard()}
        trackingConfig={latestProjectsCategoryPageNavigationClickedData(
          '/business-cards',
          'businessCards'
        )}
      >
        {t('businessCards')}
      </TrackedLink>
      <TrackedLink
        asButton
        href={linkTo.marketingMaterials()}
        trackingConfig={latestProjectsCategoryPageNavigationClickedData(
          '/marketing-materials',
          'marketingMaterials'
        )}
      >
        {t('marketingMaterials')}
      </TrackedLink>
      <TrackedLink
        asButton
        href={linkTo.invitationsAndStationery()}
        trackingConfig={latestProjectsCategoryPageNavigationClickedData(
          '/stationery',
          'invitationsAndStationery'
        )}
      >
        {t('invitationsStationery')}
      </TrackedLink>
    </EmptyStateCard>
  )
}

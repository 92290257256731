import { Box, Card, Link, SkeletonText, SkeletonTextLine, Span, StyleFontSize, Typography } from '@vp/swan'
import { NavigationClickedEventData } from '../../common/tracking/trackingData'
import { ErrorBoundary } from '../ErrorBoundary'
import { PropsWithChildren } from 'react'
import { getTracking } from '../../common/tracking/trackingUtils'
export const SNAPSHOT_TILE_HEIGHT = '120px'

interface Props {
  title: string;
  count: number;
  ctaText: string;
  emptyLink: string;
  emptyStateClickEvent: NavigationClickedEventData;
  withCountLink: string;
  withCountClickEvent: NavigationClickedEventData;
  loading: boolean;
}

export const Tile = ({
  title,
  count,
  ctaText,
  emptyLink,
  emptyStateClickEvent,
  withCountLink,
  withCountClickEvent,
  loading
}: Props) => {
  const { sendNavigationClickedEvent } = getTracking()

  const sendEmptyStateClickedEvent = () => {
    sendNavigationClickedEvent(emptyStateClickEvent)
  }

  const sendWithCountClickedEvent = () => {
    sendNavigationClickedEvent(withCountClickEvent)
  }

  const EmptyStateBody = () => (
    <TileLink href={emptyLink} onClick={sendEmptyStateClickedEvent}>
      <TileContent content={ctaText} fontSize='xsmall' />
    </TileLink>
  )

  return (
    <Card skin='link' bgc='info' style={{ height: SNAPSHOT_TILE_HEIGHT, alignContent: 'center' }}>
      <Typography fontSkin='body-small'>{title}</Typography>
      <ErrorBoundary fallback={<EmptyStateBody />}>
        {loading && <TileCountSkeleton />}
        {(!loading && count <= 0) && <EmptyStateBody />}
        {(!loading && count > 0) && (
          <TileLink href={withCountLink} onClick={sendWithCountClickedEvent}>
            <TileContent content={count} fontSize='large' />
          </TileLink>
        )}
      </ErrorBoundary>
    </Card>
  )
}

type TileLinkProps = {
  href: string;
  onClick?: () => void;
  unstyled?: boolean;
}

const TileLink = ({
  children,
  onClick,
  href,
}: PropsWithChildren<TileLinkProps>) => (
  <Link href={href} onClick={onClick} covering skin='unstyled'>
    {children}
  </Link>
)

type TileContentProps = {
  content: string | number;
  fontSize: StyleFontSize;
}
const TileContent = ({ content, fontSize }: TileContentProps) => (
  <Span fontSize={fontSize} fontWeight='bold'>{content}</Span>
)

const TileCountSkeleton = () => (
  <Box mt='2' data-testid='account-snapshot-tile-skeleton'>
    <SkeletonText>
      <SkeletonTextLine fontSize='large' />
    </SkeletonText>
  </Box>
)

export type LineItemConstructorParams = {
  productName: string;
  productId: string;
  productVersion: number;
  productIdUrl?: string;
  isDeliverable: boolean;
  estimatedArrivalDate?: Date;
  livePreviewUrl?: string;
  documentUrl?: string;
  fulfillmentOptions: Record<string, string>;
}

export class LineItem {
  readonly productName: string
  readonly productId: string
  readonly productVersion: number
  readonly productIdUrl?: string
  readonly isDeliverable: boolean
  readonly estimatedArrivalDate?: Date
  readonly livePreviewUrl?: string
  readonly documentUrl?: string
  readonly fulfillmentOptions: Record<string, string> = {}

  constructor ({
    productName,
    productId,
    productVersion,
    productIdUrl,
    isDeliverable,
    estimatedArrivalDate,
    livePreviewUrl,
    documentUrl,
    fulfillmentOptions,
  }: LineItemConstructorParams) {
    this.productName = productName
    this.productId = productId
    this.productVersion = productVersion
    this.productIdUrl = productIdUrl
    this.isDeliverable = isDeliverable
    this.estimatedArrivalDate = estimatedArrivalDate
    this.livePreviewUrl = livePreviewUrl
    this.documentUrl = documentUrl
    this.fulfillmentOptions = fulfillmentOptions
  }

  static sortItemsByDatesAsc (lineItems: LineItem[]): LineItem[] {
    const itemsWithDates = lineItems.filter(
      (lineItem) => lineItem.estimatedArrivalDate !== undefined
    )

    const itemsWithoutDates = lineItems.filter(
      (lineItem) => lineItem.estimatedArrivalDate === undefined
    )

    const sortedItemsWithDates = itemsWithDates.sort(LineItem.compareItemsByPromisedArrivalDateAsc)

    return [...sortedItemsWithDates, ...itemsWithoutDates]
  }

  private static compareItemsByPromisedArrivalDateAsc (a: LineItem, b: LineItem): number {
    if (!a.estimatedArrivalDate) {
      return -1
    }

    if (!b.estimatedArrivalDate) {
      return 1
    }

    return a.estimatedArrivalDate.getTime() - b.estimatedArrivalDate.getTime()
  }
}

import { PropsWithChildren } from 'react'
import { Box, Column, FlexBox, GridContainer, Row, Typography } from '@vp/swan'
import { useIdentity, useUserContext } from '@vp/ubik-context'
import { BrandProfileProvider } from '@vp/react-brand'
import { useLocalization } from '../../hooks/useLocalizations'
import { urlIdMap } from '../../common/urls/provider'
import { isAvailableInLocale } from '../../common/locale/helper'
import { ProjectsTile } from './ProjectsTile'
import { DesignServicesTile } from './DesignServicesTile'
import { WebsitesTile } from './WebsitesTile'
import { BrandKitTile } from './BrandKitTile'

const SnapshotColumn = ({ children }: PropsWithChildren) => (
  <Column span={6} spanXs={6}>{children}</Column>
)

export const AccountSnapshot = () => {
  const userContext = useUserContext()
  const { t } = useLocalization()
  const { identity } = useIdentity()

  if (!identity?.authorization) {
    return <></>
  }

  return (
    <FlexBox flexDirection='column' as='section' justifyContent='space-between'>
      <FlexBox justifyContent='space-between' alignItems='center'>
        <Typography as='h2' fontSkin='title-section'>{t('accountSnapshot.heading')}</Typography>
      </FlexBox>

      <Box style={{ flexGrow: 2 }}>
        <GridContainer>
          <Row style={{ rowGap: 'var(--swan-sem-space-6)' }}>
            <SnapshotColumn>
              <ProjectsTile />
            </SnapshotColumn>
            {isAvailableInLocale(urlIdMap.designServices, userContext.locale) && (
              <SnapshotColumn>
                <DesignServicesTile />
              </SnapshotColumn>
            )}
            {isAvailableInLocale(urlIdMap.websites, userContext.locale) && (
              <SnapshotColumn>
                <WebsitesTile />
              </SnapshotColumn>
            )}
            <SnapshotColumn>
              <BrandProfileProvider authorization={identity.authorization} requestor='my-account-brand-ui' includeSpeculations>
                <BrandKitTile />
              </BrandProfileProvider>
            </SnapshotColumn>
          </Row>
        </GridContainer>
      </Box>
    </FlexBox>
  )
}

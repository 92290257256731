import { useQuery } from '@tanstack/react-query'
import { Logger } from '@vp/ubik-logging'
import { FetchResponse, fetchWithAuthorizationHeader } from '../client/fetchClient'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { Tenants, WesResponse } from '../common/projects/types/WesResponse'
import { ProjectFactory } from '../common/projects/ProjectFactory'
import { Project } from '../common/projects/Project'

const REQUESTOR = 'my-account-homepage'

export const useFetchUserProjects = (page: number, pageSize: number): FetchResponse<Project[]> => {
  const { auth } = useIdentity()
  const { locale } = useUserContext()
  const logger = useLogger()

  const url = new URL('https://workentityservice.docext.cimpress.io/api/v2/works')

  url.searchParams.append(
    'tenants',
    `${Tenants.vistaprint},${Tenants.albelli},${Tenants.logomaker}`
  )
  url.searchParams.append('pageNumber', String(page))
  url.searchParams.append('pageSize', String(pageSize))
  url.searchParams.append('sortBy', 'LastModified')
  url.searchParams.append('from', REQUESTOR)

  return useQuery({
    queryKey: ['myProjects', page, pageSize],
    queryFn: () =>
      fetchUserProjects(
        String(url),
        locale,
        auth?.getCanonicalId() || '',
        auth?.getAuthorizationHeader() || '',
        logger
      ),
    enabled: !!auth?.getAuthorizationHeader(),
  })
}

const fetchUserProjects = async (
  url: string,
  locale: string,
  canonicalId: string,
  authorizationHeader: string,
  logger: Logger
) => {
  const fetchedProjects = await fetchWithAuthorizationHeader(String(url), authorizationHeader)

  return buildProjects(fetchedProjects, locale, canonicalId, authorizationHeader, logger)
}

const buildProjects = async (
  data: WesResponse[],
  locale: string,
  canonicalId: string,
  authorizationHeader: string,
  logger: Logger
): Promise<Project[]> => {
  const builtProjects = await Promise.all(
    data.map((project: WesResponse) => {
      // Notice we fallback to the merchandising metadata in case the workName is not available
      // or designPreviewUrl is not available - this is to support purchase list projects
      return ProjectFactory.createProject(
        project.tenant,
        {
          locale,
          id: project.workId,
          name: project.workName ?? project?.merchandising?.metadata?.productName,
          lastEdited: new Date(project.modified),
          editUrl: project.design?.editUrl,
          displayUrl: project.design?.displayUrl,
          productKey: project.product.key,
          productVersion: project.product.version,
          merchandisingSelections: project.merchandising.merchandisingSelections,
          designPreviewUrl:
            project.design?.metadata?.previewUrl ??
            project?.merchandising?.metadata?.productImageUrl,
        },
        canonicalId,
        authorizationHeader,
        logger
      )
    })
  )

  return builtProjects.filter((project: Project | null): project is Project => project !== null)
}

import {
  Span,
  ResponsiveImage,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileFooter,
  StandardTileImage,
  StandardTileName,
} from '@vp/swan'
import { Project } from '../../common/projects/Project'
import { useLocalization } from '../../hooks/useLocalizations'
import { latestProjectsLinkNavigationClickedData } from '../../common/tracking/trackingData'
import { TrackedLink } from '../TrackedLink'
import { formatDateWithShortMonth } from '../../common/date/dateFormat'
import styles from '../dashboard.css'

interface ProjectTileProps {
  project: Project;
}

export const ProjectTile = ({ project }: ProjectTileProps) => {
  const { t } = useLocalization()

  return (
    <StandardTile skin='product' layout='vertical' imageWidth='full-bleed'>
      <StandardTileImage>
        <ResponsiveImage
          paddingTop={{ xs: 4, md: 5 }}
          paddingLeft={{ xs: 4, md: 5 }}
          paddingRight={{ xs: 4, md: 5 }}
          src={project.previewImageUrl || ''}
          alt={t('latestProjects.projectThumbnailImageAltText', {
            productName: project.name,
          })}
        />
      </StandardTileImage>
      <StandardTileContents>
        <StandardTileName className={styles.projectsHeading}>{project.name}</StandardTileName>
        <StandardTileDescription>
          <Span>
            {t('latestProjects.editedLabel')} {formatDateWithShortMonth(project.lastEdited, project.locale)}
          </Span>
        </StandardTileDescription>
      </StandardTileContents>
      <StandardTileFooter>
        {project.editUrl && (
          <TrackedLink
            href={project.editUrl}
            trackingConfig={latestProjectsLinkNavigationClickedData(project.id)}
            asButton
            fullWidth
            skin='primary'
          >
            {t('latestProjects.edit')}
          </TrackedLink>
        )}
      </StandardTileFooter>
    </StandardTile>
  )
}

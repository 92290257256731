const REQUESTOR = 'my-account-homepage'

type fetchStudioEditUrlParams = {
  productKey: string;
  productVersion: string;
  locale: string;
  workId: string;
}

type StarsStudioRoutingResponse = {
  url: {
    href: string;
  };
}

export const fetchStudioEditUrl = async ({
  productKey,
  productVersion,
  locale,
  workId,
}: fetchStudioEditUrlParams): Promise<StarsStudioRoutingResponse> => {
  const url = new URL(
    'https://studio-available-routes-service.design.vpsvc.com/v1/studioRouting/studio'
  )
  url.searchParams.append('productKey', productKey)
  url.searchParams.append('productVersion', productVersion.toString())
  url.searchParams.append('locale', locale)
  url.searchParams.append('designOptionType', 'work')
  url.searchParams.append('designOptionValue', workId)
  url.searchParams.append('requestor', REQUESTOR)

  const response = await fetch(String(url))
  if (!response.ok) {
    throw new Error(
      `Failed to fetch studio edit url for productKey: ${productKey}, productVersion: ${productVersion}, workId: ${workId}`
    )
  }

  return response.json()
}

import { Card, FlexBox, Icon, Typography } from '@vp/swan'
import { Order } from '../Order'
import { useIdentity } from '@vp/ubik-context'
import { useLocalization } from '../../../hooks/useLocalizations'
import { useUrls } from '../../../common/urls/provider'
import { formatDateWithShortMonthAndYear } from '../../../common/date/dateFormat'
import { RoundedPreview } from './RoundedPreview'
import { RoundedFluidImage } from './RoundedFluidImage'
import { TrackedLink } from '../../TrackedLink'
import { ordersDetailsNavigationClickedData } from '../../../common/tracking/trackingData'
import { SNAPSHOT_TILE_HEIGHT } from '../../AccountSnapshot/Tile'

interface OrderTileProps {
  order: Order;
}

export function OrderTile ({ order }: OrderTileProps) {
  const { identity } = useIdentity()
  const { t } = useLocalization()
  const { linkTo } = useUrls()

  const deliveryDate = order.soonestArrivalDate
    ? formatDateWithShortMonthAndYear(order.soonestArrivalDate, order.locale)
    : t('orders.pendingStatus')

  const imageAlt: string = t('orders.orderThumbnailImageAltText', {
    productName: order.productName,
  })

  const estimatedDeliveryText = t('orders.estimatedDelivery', { date: deliveryDate })

  const shouldRenderRoundedFluidImage = order.previewUrl && order.previewUrl !== ''
  const shouldRenderRoundedPreview =
    !shouldRenderRoundedFluidImage && order.documentUrl && order.documentUrl !== ''
  const shouldRenderFallback = !shouldRenderRoundedFluidImage && !shouldRenderRoundedPreview

  return (
    <FlexBox flexDirection='row' gap='6'>
      {shouldRenderRoundedPreview && (
        <RoundedPreview
          documentUrl={order.documentUrl as string}
          referenceId={order.productId}
          version={order.productVersion}
          attributes={order.fulfillmentOptions}
          authorizationHeader={identity?.authorizationHeader}
          style={{
            height: SNAPSHOT_TILE_HEIGHT,
            width: SNAPSHOT_TILE_HEIGHT,
          }}
          alt={imageAlt}
        />
      )}
      {shouldRenderRoundedFluidImage && (
        <RoundedFluidImage
          url={order.previewUrl as string}
          alt={imageAlt}
          style={{
            height: SNAPSHOT_TILE_HEIGHT,
            width: SNAPSHOT_TILE_HEIGHT,
          }}
        />
      )}
      {shouldRenderFallback && (
        <Card fullBleed overflow='hidden' style={{ height: SNAPSHOT_TILE_HEIGHT, width: SNAPSHOT_TILE_HEIGHT }} bgc='strong' padding={4}>
          <FlexBox
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            style={{ height: '100%' }}
          >
            <Icon
              data-testid='fallback-icon'
              iconType='image'
              size='standard'
              alt={imageAlt}
              aria-hidden='true'
            />
          </FlexBox>
        </Card>
      )}
      <FlexBox
        flexDirection='column'
        justifyContent='center'
        overflow='hidden'
      >
        <Typography fontSize='small' fontWeight='bold' truncate>
          {order.productName}
        </Typography>

        <Typography fontSize='xsmall'>{estimatedDeliveryText}</Typography>

        <TrackedLink
          href={linkTo.orderDetails(order.number)}
          skin='cta'
          trackingConfig={ordersDetailsNavigationClickedData(order.number)}
        >
          {t('orders.orderDetailsLabel')}
        </TrackedLink>
      </FlexBox>
    </FlexBox>
  )
}

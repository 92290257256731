export const formatDateWithShortMonth = (date: Date, locale?: string | null) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  }

  return date.toLocaleDateString(locale ?? undefined, options)
}

export const formatDateWithShortMonthAndYear = (date: Date, locale?: string | null) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }

  return date.toLocaleDateString(locale ?? undefined, options)
}

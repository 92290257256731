import { Box, FlexBox, Link, Typography } from '@vp/swan'
import { Order } from '../Order'
import { OrderTile } from '../OrderTile/OrderTile'
import { useLocalization } from '../../../hooks/useLocalizations'
import { useUrls } from '../../../common/urls/provider'
import { TrackedLink } from '../../TrackedLink'
import { ordersContinueShoppingNavigationClickedData } from '../../../common/tracking/trackingData'

export interface OrderUpdateListProps {
  orders: Order[];
  hasError: boolean;
  refetch: () => void;
}

export function OrderUpdateList ({ orders, hasError, refetch }: OrderUpdateListProps) {
  if (hasError) {
    return <OrderUpdateListErrorState onRetry={refetch} />
  }

  if (orders.length === 0) {
    return <NoOrdersState />
  }

  return (
    <FlexBox flexDirection='column' style={{ height: '100%' }} gap={6}>
      {orders.map((order, idx) => {
        return (
          <Box key={order.number} style={{ flexGrow: 2 }}>
            <OrderTile order={order} />
          </Box>
        )
      })}
    </FlexBox>
  )
}

export function OrderUpdateListErrorState ({ onRetry }: { onRetry: () => void }) {
  const { t } = useLocalization()
  return (
    <Typography fontSize='small'>
      {t('orders.contentFailedToLoad') + ' '}
      <Link onClick={onRetry}>{t('tryAgain')}</Link>
    </Typography>
  )
}

function NoOrdersState () {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  return (
    <Typography fontSize='small'>
      {t('orders.noOrdersMessage') + ' ' + t('orders.firstOrderNotification') + ' '}
      <TrackedLink
        href={linkTo.home()}
        trackingConfig={ordersContinueShoppingNavigationClickedData}
      >
        {t('orders.continueShoppingCta')}
      </TrackedLink>
    </Typography>
  )
}

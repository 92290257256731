import { Column, GridContainer, Row } from '@vp/swan'
import { Project } from '../../common/projects/Project'
import { ProjectTile } from './ProjectTile'

interface ProjectsPreviewTilesProps {
  projects: Project[];
}

export const ProjectTileList = ({ projects }: ProjectsPreviewTilesProps) => (
  <GridContainer>
    <Row>
      {projects.map((project: Project) => (
        <Column key={project.id} spanXs={6} span={3}>
          <ProjectTile project={project} />
        </Column>
      ))}
    </Row>
  </GridContainer>
)

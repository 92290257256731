import { useEffect } from 'react'
import { useLogger } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'
import { useUrls } from '../../common/urls/provider'
import {
  accountSnapshotDesignServicesEmptyTileNavigationClickedData,
  accountSnapshotDesignServicesTileNavigationClickedData,
} from '../../common/tracking/trackingData'
import { useFetchBifrostProjectsCount } from '../../hooks/useFetchBifrostProjectsCount'
import { Tile } from './Tile'

export const DesignServicesTile = () => {
  const { t } = useLocalization()
  const { linkTo } = useUrls()
  const logger = useLogger()

  const { error, isLoading, data: count } = useDesignServicesCount()

  useEffect(() => {
    if (error) {
      logger.error(new Error('Account snapshot: failed to fetch design services count'), error as Error)
    }
  }, [error])

  return (
    <Tile
      title={t('accountSnapshot.designServicesHeading')}
      count={error ? 0 : count ?? 0}
      ctaText={t('accountSnapshot.startProjectCta')}
      emptyLink={linkTo.designServices()}
      emptyStateClickEvent={accountSnapshotDesignServicesEmptyTileNavigationClickedData}
      withCountLink={linkTo.myDesignServices()}
      withCountClickEvent={accountSnapshotDesignServicesTileNavigationClickedData}
      loading={isLoading}
    />
  )
}

const useDesignServicesCount = (): { data: number; isLoading: boolean; error: unknown } => {
  const {
    data: bifrostData,
    isLoading,
    error,
  } = useFetchBifrostProjectsCount()

  if (isLoading || error) {
    return { data: 0, isLoading, error }
  }

  const count = bifrostData?.count ?? 0

  return { data: count, isLoading, error }
}

import { Preview, User } from '@rendering/product-preview-react'
import { LookupRequest } from '@rendering/lookup'

import { CSSProperties } from 'react'
import { Card, FlexBox, Spinner } from '@vp/swan'
import { useGetProductPanelView } from '../../../hooks/useGetProductPanelPreview'
import { useResolvedDocumentUrl } from '../../../hooks/useResolvedDocumentUrl'

export type RoundedImageProps = {
  documentUrl: string;
  referenceId: string;
  version: number;
  attributes: Record<string, string>;
  style?: CSSProperties;
  authorizationHeader?: string;
  alt: string;
}

const buildPreviewRequest = (params: {
  referenceId: string;
  version: number;
  attributes?: Record<string, string>;
}): LookupRequest => {
  const { referenceId, version, attributes } = params
  return {
    reference: {
      id: referenceId,
      version,
      attributes: attributes || {},
    },
    tenant: {
      id: 'vistaprint',
      type: 'merchant',
    },
    previewType: ['scene', 'panel'],
  }
}

const buildPreviewUser = (): User => {
  return {
    tenant: {
      id: 'vistaprint',
      type: 'merchants',
    },
    referer: 'my account dashboard page',
  }
}

export const RoundedPreview = ({
  documentUrl,
  referenceId,
  version,
  attributes,
  style,
  alt,
  authorizationHeader,
}: RoundedImageProps) => {
  const previewRequest = buildPreviewRequest({
    referenceId,
    version,
    attributes,
  })

  const user = buildPreviewUser()

  const { data: view, isLoading: isPreviewLoading } = useGetProductPanelView({
    authorizationHeader: authorizationHeader ?? '',
    environment: 'prod',
    request: previewRequest,
  })

  const { data: documentReferenceUrl, isLoading: isDocumentReferenceLoading } =
    useResolvedDocumentUrl(documentUrl)

  return (
    <Card
      fullBleed
      overflow='hidden'
      style={style}
      bgc='strong'
      padding={4}
      aria-label={alt}
      role='img'
      aria-busy={isPreviewLoading}
    >
      {(isPreviewLoading || isDocumentReferenceLoading) && (
        <FlexBox justifyContent='center' flexDirection='column' style={{ height: '100%' }}>
          <Spinner accessibleText={alt} role='alert' aria-live='polite' />
        </FlexBox>
      )}
      {!isPreviewLoading && view && documentReferenceUrl && (
        <Preview user={user} view={view} cimDocUrl={documentReferenceUrl} />
      )}
    </Card>
  )
}

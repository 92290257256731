type PageTrackingData = {
  pageName: string;
  pageSection: string;
  pageStage: string;
}

export type NavigationClickedEventData = {
  category: string;
  label: string;
  eventDetail: string;
  navigationDetail: string;
  pageSection: string;
  pageStage: string;
  pageName: string;
  pageDept: string;
}

const trackingPageData: PageTrackingData = {
  pageName: 'My Account:Dashboard',
  pageSection: 'My Account',
  pageStage: 'My Account',
}

const dashboardNavigationSharedData = {
  ...trackingPageData,
  pageDept: 'My Account:Dashboard',
  category: 'My Account',
}

/* --- Account Snapshot --- */

export const accountSettingsNavigationClickedData: NavigationClickedEventData = {
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Header',
  eventDetail: '/my-account;/account-settings;Dashboard - Header;Account settings',
  navigationDetail: 'Account settings',
}

const accountSnapshotTileNavigationClickedData = (
  tileName: string,
  eventDetailDestination: string
): NavigationClickedEventData => ({
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Account Snapshot',
  eventDetail: `/my-account;${eventDetailDestination};Dashboard - Account Snapshot;${tileName}`,
  navigationDetail: tileName,
})

export const accountSnapshotProjectsTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Print Projects', '/my-account/design/projects')

export const accountSnapshotDesignServicesTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Design Services', '/graphic-design/dashboard')

export const accountSnapshotWebsitesTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Websites', '/workspace')

export const accountSnapshotBrandKitsTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Brand Kits', '/brand-kit')

export const accountSnapshotProjectsEmptyTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Print Projects', '/')

export const accountSnapshotDesignServicesEmptyTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Design Services', '/design')

export const accountSnapshotWebsitesEmptyTileNavigationClickedData =
  accountSnapshotTileNavigationClickedData('Websites', '/digital-marketing/websites')

/* --- Orders --- */

export const ordersViewAllNavigationClickedData: NavigationClickedEventData = {
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Orders',
  eventDetail: '/my-account;/oh;Dashboard - Orders;View all',
  navigationDetail: 'View all',
}

export const ordersContinueShoppingNavigationClickedData: NavigationClickedEventData = {
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - My Orders',
  eventDetail: '/my-account;/;Dashboard - My Orders;Continue Shopping',
  navigationDetail: 'Continue Shopping',
}

export const ordersDetailsNavigationClickedData = (
  orderId: string
): NavigationClickedEventData => ({
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Orders',
  eventDetail: '/my-account;/od;Dashboard - Orders;order',
  navigationDetail: `order:${orderId}`,
})

/* --- Latest Projects --- */

export const latestProjectsViewAllNavigationClickedData: NavigationClickedEventData = {
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Latest Projects',
  eventDetail: '/my-account;/my-account/design/projects;Dashboard - Latest Projects;View all',
  navigationDetail: 'View all',
}

export const latestProjectsCategoryPageNavigationClickedData = (
  path: string,
  mpvId: string
): NavigationClickedEventData => ({
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Latest Projects',
  eventDetail: `/my-account;${path};Dashboard - Latest Projects;${mpvId}`,
  navigationDetail: mpvId,
})

export const latestProjectsLinkNavigationClickedData = (
  workId: string
): NavigationClickedEventData => ({
  ...dashboardNavigationSharedData,
  label: 'Content:Dashboard - Latest Projects',
  eventDetail: '/my-account;/studio;Dashboard - Latest Projects;project',
  navigationDetail: `project:${workId}`,
})

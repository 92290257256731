import { useQuery } from '@tanstack/react-query'
import { getViews, GetViewsParams, View } from '@rendering/lookup'
import { FetchResponse } from '../client/fetchClient'

export const DEFAULT_VIEW_WHEN_NO_DATA: View = {
  name: 'Default view product name',
  purpose: 'Product',
  subPurpose: 'Full',
  type: 'panel',
  panel: {
    page: 1,
    aspectRatio: 1,
  },
}

export const useGetProductPanelView = (
  getProductImageParams: GetViewsParams
): FetchResponse<View> => {
  return useQuery({
    queryKey: [`product-panel-preview-${getProductImageParams.request.reference.id}`],
    queryFn: () => getViews(getProductImageParams),
    select: (data: View[]) => getFirstAvailableView(data as View[]),
    enabled: !!getProductImageParams.authorizationHeader,
  })
}

const getFirstAvailableView = (availableViews: View[]): View | undefined => {
  if (availableViews.length === 0) {
    return DEFAULT_VIEW_WHEN_NO_DATA
  }

  const firstSceneView = availableViews.find((view) => view.type === 'scene')
  if (firstSceneView !== undefined) {
    return firstSceneView
  }

  return availableViews[0]
}

import { FallbackProps } from 'react-error-boundary'
import {
  OrderUpdateList,
  OrderUpdateListErrorState,
  OrderUpdateListProps,
} from '../OrderUpdateList/OrderUpdateList'
import { useLocalization } from '../../../hooks/useLocalizations'
import { ErrorBoundary } from '../../ErrorBoundary'
import { useUrls } from '../../../common/urls/provider'
import { Box, FlexBox, H2 } from '@vp/swan'
import { TrackedLink } from '../../TrackedLink'
import { ordersViewAllNavigationClickedData } from '../../../common/tracking/trackingData'

export function OrderUpdateSection ({ orders, hasError, refetch }: OrderUpdateListProps) {
  const { t } = useLocalization()
  const { linkTo } = useUrls()

  const fallback = ({ resetErrorBoundary }: FallbackProps) => (
    <OrderUpdateListErrorState onRetry={resetErrorBoundary} />
  )

  const shouldViewAll = orders.length > 0 || hasError

  return (
    <FlexBox
      flexDirection='column'
      as='section'
      data-testid='content-section'
      justifyContent='space-between'
    >
      <FlexBox
        justifyContent='space-between'
        alignItems='center'
        data-testid='content-section-header'
      >
        <H2 fontSkin='title-section'>{t('orders.heading')}</H2>
        {shouldViewAll && (
          <TrackedLink
            href={linkTo.myOrderHistory()}
            trackingConfig={ordersViewAllNavigationClickedData}
          >
            {t('viewAll')}
          </TrackedLink>
        )}
      </FlexBox>
      <Box style={{ flexGrow: 2 }}>
        <ErrorBoundary fallbackRender={fallback}>
          <OrderUpdateList orders={orders} hasError={hasError} refetch={refetch} />
        </ErrorBoundary>
      </Box>
    </FlexBox>
  )
}

import { useQuery } from '@tanstack/react-query'
import { FetchResponse, fetchWithAuthorizationHeader } from '../client/fetchClient'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { DaliResponse } from '../client/DaliOrder/DaliResponse'
import { Order } from '../client/DaliOrder/Order'
import { DaliOrder } from '../client/DaliOrder/DaliOrder'
import { DaliLineItem } from '../client/DaliOrder/DaliLineItem'
import { LineItem } from '../client/DaliOrder/LineItem'
import { getTenantAndProductionOverrides } from '../utils/getTenantAndProductionOverrides'
import { Logger } from '@vp/ubik-logging'

const PAGE_SIZE = 2

export const useFetchUserOrders = (isProduction: boolean): FetchResponse<Order[]> => {
  const { identity, auth } = useIdentity()
  const { locale } = useUserContext()
  const logger = useLogger()
  const { productionOverride, tenantOverride } = getTenantAndProductionOverrides()

  const country = locale.split('-')[1].toUpperCase()

  const orderManagementTenant = tenantOverride ?? `VP-${country}-${isProduction ? 'PROD' : 'DEV'}`
  const baseUrl = `https://dali${
    productionOverride ?? isProduction ? '' : '-staging'
  }.orders.vpsvc.com/api`

  const url = `${baseUrl}/shoppers/${auth?.getCanonicalId()}/orders?page=0&pageSize=${PAGE_SIZE}&tenantId=${orderManagementTenant}`

  return useQuery({
    queryKey: ['myOrders', locale, auth?.getCanonicalId()],
    queryFn: () => fetchWithAuthorizationHeader(url, identity?.authorizationHeader || ''),
    select: (data) => buildOrders(data, locale, logger),
    enabled: !!identity?.authorizationHeader,
  })
}

const buildOrders = (data: DaliResponse, locale: string, logger : Logger): Order[] => {
  const orders = data.orders.filter((order: DaliOrder) => {
    if (!order.lineItems) {
      logger.error('No line items found for order request', {
        orderId: order.orderId,
      })
      return false
    }
    return true
  })

  return orders.map((order: DaliOrder) => {
    const lineItems = order.lineItems.map((lineItem: DaliLineItem) => {
      return new LineItem({
        productName: lineItem.productName,
        productId: lineItem.productKey,
        productVersion: lineItem.productVersion,
        productIdUrl: lineItem.livePreviewUrl,
        isDeliverable: lineItem.isDeliverable,
        estimatedArrivalDate: new Date(lineItem.promisedArrivalDate),
        livePreviewUrl: lineItem.livePreviewUrl,
        documentUrl: lineItem.documentUrl,
        fulfillmentOptions: lineItem.fulfillmentOptions,
      })
    })
    return new Order({
      number: order.orderNumber,
      status: order.state,
      locale,
      lineItems,
    })
  })
}

import { useQuery } from '@tanstack/react-query'
import { FetchResponse } from '../client/fetchClient'
import { useIdentity, useUserContext } from '@vp/ubik-context'

const API_URL = 'https://graph.es.vpsvc.com/graph'

interface YourWorkItemsResponse {
  yourWorkItems: {
    resultCount: number;
  };
}

interface ProjectsCount {
  count: number;
}

export const useFetchBifrostProjectsCount = (): FetchResponse<ProjectsCount> => {
  const { identity, auth } = useIdentity()
  const { locale } = useUserContext()

  return useQuery({
    queryKey: ['BifrostServicesProjects', locale, auth?.getCanonicalId()],
    queryFn: () => fetchUserProjectsCount(identity?.authorizationHeader || ''),
    enabled: !!identity?.authorizationHeader,
  })
}

const fetchUserProjectsCount = async (authorizationHeader: string): Promise<ProjectsCount> => {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationHeader,
      },
      body: JSON.stringify({
        query: `
          query {
            yourWorkItems(input: { page: 1, status: IN_PROGRESS, tenant: "vistaprint" }) {
              resultCount
            }
          }
        `,
      }),
    })

    if (!response.ok) {
      throw new Error(`GraphQL request failed with status ${response.status}`)
    }

    const result = await response.json() as any
    if (result.errors) {
      throw new Error(`GraphQL errors: ${JSON.stringify(result.errors)}`)
    }

    const fetchedProjects: YourWorkItemsResponse = result.data
    return { count: fetchedProjects.yourWorkItems.resultCount }
  } catch (error) {
    throw new Error(`failed retrieving 99d design requests: ${error}`)
  }
}

import { useQuery } from '@tanstack/react-query'
import { FetchResponse, fetchWithAuthorizationHeader } from '../client/fetchClient'

export type DocumentReference = {
  _links: {
    self: {
      href: string;
    };
    document: {
      href: string;
    };
    documentRevision: {
      href: string;
    };
    previewInstructions: {
      href: string;
    };
    drawingInstructions: {
      href: string;
    };
  };
  cimDocUrl: string;
  prepressInstructionsUrl: string;
  renderingInstructionsUrl: string;
  metadata: {
    Url: string;
  };
}

export const useResolvedDocumentUrl = (documentUrl: string): FetchResponse<string | undefined> => {
  return useQuery({
    queryKey: [`resolve-document-url-${documentUrl}`],
    queryFn: () => fetchWithAuthorizationHeader(documentUrl, ''),
    select: (data: DocumentReference) => getSelfDocumentHref(data),
    enabled: !!documentUrl,
  })
}

const getSelfDocumentHref = (documentReference: DocumentReference): string | undefined => {
  return documentReference._links?.document?.href
}

import { CSSProperties } from 'react'
import { Card, FlexBox, FluidImage } from '@vp/swan'

export type RoundedImageProps = {
  url: string;
  alt: string;
  style?: CSSProperties;
}

export const RoundedFluidImage = ({ url, alt, style }: RoundedImageProps) => {
  return (
    <Card fullBleed overflow='hidden' style={style} bgc='strong' padding={4}>
      <FlexBox justifyContent='center' flexDirection='column' style={{ height: '100%' }}>
        <FluidImage loading='lazy' src={url} alt={alt} />
      </FlexBox>
    </Card>
  )
}

import { Logger } from '@vp/ubik-logging'
import { Tenants } from './types/WesResponse'
import { AlbelliProject } from './models/AlbelliProject'
import { LogomakerProject } from './models/LogomakerProject'
import { Project, ProjectConstructorParams } from './Project'
import { WesProject } from './models/WesProject'
import { fetchStudioEditUrl } from './services/stars'
import { fetchAlbelliProject } from './services/albelliProjectService'
import { fetchMSXProductNames } from './services/msxService'

export class ProjectFactory {
  static async createProject (
    tenant: Tenants,
    params: ProjectConstructorParams & {
      merchandisingSelections?: Record<string, string>;
      designPreviewUrl?: string;
    },
    canonicalId: string,
    authorizationHeader: string,
    logger?: Logger
  ): Promise<Project | null> {
    if (ProjectFactory.isAffectedByLegacyEditUrlIssue(params.locale, params.editUrl)) {
      const studioEditUrlResponse = await fetchStudioEditUrl({
        productKey: params.productKey,
        productVersion: params.productVersion,
        locale: params.locale,
        workId: params.id,
      })

      params.editUrl = studioEditUrlResponse.url.href
    }

    if (tenant === Tenants.albelli) {
      ProjectFactory.getDocId(params)
      const docId = ProjectFactory.getDocId(params)

      if (docId) {
        await ProjectFactory.appendAlbelliDisplayUrl(
          params,
          docId,
          canonicalId,
          authorizationHeader
        )
      }

      return new AlbelliProject(params)
    } else if (tenant === Tenants.vistaprint) {
      if (!params.name) {
        const productName = await ProjectFactory.getMSXProductName(
          params.locale,
          params.productKey,
          authorizationHeader,
          logger
        )

        if (productName) {
          params.name = productName
        }
      }

      return new WesProject(params)
    } else if (tenant === Tenants.logomaker) {
      return new LogomakerProject(params)
    } else {
      logger?.error(new Error('unknown project tenant'), { tenant })
      return null
    }
  }

  private static isAffectedByLegacyEditUrlIssue (locale: string, url: string | null): boolean {
    const affectedLocales = ['fr-ch', 'it-ch', 'fr-be']
    return (affectedLocales.includes(locale.toLowerCase()) && url?.includes('/studio')) || false
  }

  private static getDocId (params: ProjectConstructorParams): string {
    let docId = ''
    if (params.editUrl?.includes('?')) {
      const queryString = params.editUrl.split('?')[1]
      const queryParams = new URLSearchParams(queryString)
      docId = queryParams.get('docId') || ''
    }
    return docId
  }

  private static async appendAlbelliDisplayUrl (
    params: ProjectConstructorParams,
    docId: string,
    canonicalId: string,
    authorizationHeader: string
  ) {
    const albelliProjectResponse = await fetchAlbelliProject({
      docId,
      canonicalId,
      authorizationHeader,
    })
    params.displayUrl = albelliProjectResponse.previewImage.imageUrl
  }

  private static async getMSXProductName (
    locale: string,
    productKey: string,
    authorizationHeader: string,
    logger?: Logger
  ) {
    try {
      const msxProductNameResponse = await fetchMSXProductNames({
        locale,
        productKeys: [productKey],
        authorizationHeader,
      })

      if (!msxProductNameResponse?.[productKey]) {
        return null
      }

      const productName = msxProductNameResponse[productKey].toString()

      return productName
    } catch (error) {
      logger?.error(error as Error)
      return null
    }
  }
}

import { DEFAULT_LOCALE } from '../../utils/constants'

export const locales = [
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-NZ',
  'en-SG',
  'en-US',
  'es-ES',
  'es-US',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'it-CH',
  'it-IT',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'pt-PT',
  'sv-SE',
] as const
export const pageLocaleDefault = DEFAULT_LOCALE
export const translationLocaleDefault = DEFAULT_LOCALE
export type SupportedLocale = (typeof locales)[number]

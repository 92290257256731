import { useQuery } from '@tanstack/react-query'
import { useIdentity } from '@vp/ubik-context'
import { FetchResponse, fetchWithAuthorizationHeader } from '../client/fetchClient'

export type UserProfile = {
  canonicalId: string;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  pictureURL: string;
  identityProviders: IdentityProvider[];
  isTestUser: boolean;
}

export type IdentityProvider = {
  id: string;
  provider: string;
}

export const useFetchUserProfile = (): FetchResponse<UserProfile> => {
  const { auth, identity } = useIdentity()

  return useQuery({
    queryKey: [`userProfile-${auth?.getCanonicalId()}`],
    queryFn: () => fetchProfile({ authorizationHeader: identity?.authorizationHeader ?? '' }),
    enabled: !!identity?.authorizationHeader,
  })
}

const BUSINESS_ACCOUNT_ID = 'ozoDdrmewShEcbUDWX8J3V'

type fetchProfileParams = {
  authorizationHeader: string;
}

export const fetchProfile = async ({
  authorizationHeader,
}: fetchProfileParams): Promise<UserProfile> => {
  const url = new URL(`https://profile.cimpress.io/v1/${BUSINESS_ACCOUNT_ID}/profile/me`)

  return await fetchWithAuthorizationHeader(String(url), authorizationHeader)
}
